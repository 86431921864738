/* Mixins */
.things-model-type-component {
  margin-bottom: -24px;
}
.things-model-type-component .form-item__data-range .ant-input {
  display: inline-block;
  width: calc(50% - 8.8px);
}
.things-model-type-component .form-item__data-bool-true .ant-input {
  width: calc(100% - 42px);
  margin-left: 3px;
}
.things-model-type-component .form-item__data-bool-false .ant-input {
  width: calc(100% - 42px);
}
.things-model-type-component .ant-form-item {
  width: 100%;
}
.things-model-type-component .enum-remove-link {
  position: absolute;
  width: 36px;
  margin-left: 8px;
}