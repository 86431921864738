/* Mixins */
.jtl-image-component[data-v-52ca0742] {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
}
.jtl-image-component img[data-v-52ca0742] {
  max-height: 100%;
  max-width: 100%;
}
.jtl-image-component img.load-error[data-v-52ca0742] {
  width: 50%;
  max-width: 262px;
}