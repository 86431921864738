.jtl-cron-modal .ant-modal-body {
  padding: 0px;
}
.jtl-cron-modal .ant-modal-body .card-container {
  background: #fff;
  overflow: hidden;
  padding: 0px;
  position: relative;
  width: 100%;
}
.jtl-cron-modal .ant-modal-body .card-container .ant-tabs {
  padding: 0;
}
.jtl-cron-modal .ant-modal-body .card-container .ant-tabs .ant-tabs-tabpane {
  padding: 0 0 0 15px;
}
.jtl-cron-modal .ant-modal-body .card-container .ant-tabs .ant-tabs-tabpane .ant-row {
  margin: 10px 0;
}
.jtl-cron-modal .ant-modal-body .card-container .ant-tabs .ant-tabs-tabpane .ant-select,
.jtl-cron-modal .ant-modal-body .card-container .ant-tabs .ant-tabs-tabpane .ant-input-number {
  width: 100px;
}
.jtl-cron-modal .container-widthEn {
  width: 755px;
}
.jtl-cron-modal .container-widthCn {
  width: 608px;
}
.jtl-cron-modal .language {
  text-align: center;
  position: absolute;
  right: 13px;
  top: 13px;
  border: 1px solid transparent;
  height: 40px;
  line-height: 38px;
  font-size: 16px;
  color: #409eff;
  z-index: 1;
  background: #f5f7fa;
  outline: none;
  width: 47px;
  border-bottom: 1px solid #e6ebf5;
  border-radius: 0;
}
.jtl-cron-modal .card-container .bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px;
}
.jtl-cron-modal .card-container .bottom .cronButton {
  margin: 0 10px;
  line-height: 40px;
}
.jtl-cron-modal .tabBody .a-row {
  margin: 10px 0;
}
.jtl-cron-modal .tabBody .a-row .long .a-select {
  width: 354px;
}
.jtl-cron-modal .tabBody .a-row .a-input-number {
  width: 110px;
}
