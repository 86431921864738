/* Mixins */
.data-param-form-component .data-param-wrap {
  margin-left: 8px;
  margin-bottom: 8px;
}
.data-param-form-component .data-param-wrap .data-param-row .data-param-col-name {
  border: 1px solid #e1e2e5;
  background: #e3f2fd;
  height: 40px;
  line-height: 40px;
}