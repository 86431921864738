/* Mixins */
.jtl-qrcode-component {
  background-color: #fff;
  max-width: 500px;
}
.jtl-qrcode-component .qrcode-wrapper {
  height: 100%;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.jtl-qrcode-component .qrcode-wrapper img {
  width: 100%;
}
.qrcode-modal .ant-modal {
  width: 475px !important;
}
.qrcode-modal .ant-modal-body {
  padding: 0;
}
.qrcode-modal-content {
  padding: 0 !important;
  height: 281px;
  width: 100%;
}
.qrcode-modal-content .qrcode-logo {
  height: 50px;
  color: #000;
  border-bottom: solid 1px #bbb;
}
.qrcode-modal-content .qrcode-logo img {
  height: 40px;
  margin-top: 5px;
}
.qrcode-modal-content .qrcode {
  width: 100%;
}
.qrcode-modal-content .factory-code {
  position: absolute;
  left: 8px;
  bottom: 0;
  font-size: 16px;
}
.qrcode-modal-content .factory-code .factory-code-label {
  color: #575757;
}
.qrcode-modal-download .ant-modal-content {
  position: absolute;
  top: -9999px;
  left: -9999px;
}