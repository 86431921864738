/* Mixins */
.jtl-tree-group-component {
  width: 100%;
}
.jtl-tree-group-component .tree-group-spin {
  min-height: 200px;
}
.jtl-tree-group-component .jtl-tree {
  width: 100%;
}
.jtl-tree-group-component .jtl-tree .ant-tree-node-content-wrapper {
  width: calc(100% - 20px);
}
.jtl-tree-group-component .jtl-tree .ant-tree-node-content-wrapper .ant-tree-title .jtl-tooltip-component {
  display: inline-block;
  width: calc(100% - 18px);
}
.jtl-tree-group-component .jtl-tree .ant-tree-node-content-wrapper .tree-item-action-dot {
  margin-right: -4px;
}
.jtl-tree-group-component .jtl-tree .ant-tree-node-selected {
  font-weight: bold;
}