/* Mixins */
.jtl-tree-professional-component .jtl-tree {
  width: 100%;
}
.jtl-tree-professional-component .jtl-tree .ant-tree-node-content-wrapper {
  width: calc(100% - 20px);
}
.jtl-tree-professional-component .jtl-tree .ant-tree-node-content-wrapper .ant-tree-title .jtl-tooltip-component {
  display: inline-block;
  width: calc(100% - 18px);
}
.jtl-tree-professional-component .jtl-tree .ant-tree-node-selected {
  font-weight: bold;
}