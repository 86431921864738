/* Mixins */
.jtl-card-component {
  border-radius: 2px;
  width: 100%;
  background-color: #fff;
}
.jtl-card-component .card-header {
  padding: 16px 16px 8px 16px;
  line-height: 24px;
}
.jtl-card-component .card-header-title {
  font-weight: bold;
}