/* Mixins */
.app-componnet {
  position: relative;
  height: 100%;
  min-width: 768px;
  min-height: 300px;
}
.app-componnet .full-screen-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 99999;
  background-color: #000;
  opacity: 0.6;
}
.app-componnet .full-screen-loading .ant-spin-text {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 80px;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
  font-size: 32px;
  font-weight: bold;
}