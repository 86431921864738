.ant-skeleton {
  background-color: #fff;
}
.ant-modal .ant-modal-close {
  color: #fff;
}
.ant-modal .ant-modal-close .ant-modal-close-x {
  height: 46px;
  width: 46px;
  line-height: 46px;
}
.ant-modal .ant-modal-header {
  background: #28549e !important;
  padding: 12px 24px;
}
.ant-modal .ant-modal-header .ant-modal-title {
  color: #fff;
}
.ant-modal .ant-modal-body {
  min-height: 163px;
}
.ant-modal .ant-modal-content .ant-modal-close-icon {
  color: #fff !important;
}
.ant-modal .ant-modal-footer {
  background: #F0F2F5;
  padding: 8px 16px;
}
.ant-modal .ant-modal-footer .ant-btn {
  margin-left: 8px;
}
.ant-select {
  min-width: 100px;
}
.ant-select-dropdown-menu-item-group-list {
  padding-left: 20px;
}
.ant-menu .ant-menu-item {
  width: 100% !important;
}
.ant-card .ant-card-head .ant-card-head-title {
  font-size: 14px;
  font-weight: bold;
  padding: 12px 0;
}
.ant-card .ant-card-head .ant-card-extra {
  padding: 0;
}
.ant-card .ant-card-head .ant-card-extra .table-header-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 47px;
  line-height: 47px;
}
.ant-card .ant-card-head .ant-card-extra .table-header-button [table-header-child] {
  margin-left: 8px;
}
.ant-card .ant-card-head .card-title {
  margin-right: 16px;
}
.ant-card .ant-card-body {
  padding: 12px;
  line-height: 1;
}
.ant-card .ant-card-body .ant-table-wrapper {
  width: 100%;
}
.ant-tree .ant-tree-node-content-wrapper .tree-item-action-wrapper {
  display: none;
  float: right;
}
.ant-tree .ant-tree-node-content-wrapper .tree-item-action-dot {
  padding: 0 8px;
}
.ant-tree .ant-tree-node-content-wrapper .tree-item-action-dot:hover {
  background-color: var(--primaryColor);
  color: #fff;
  font-weight: bold;
}
.ant-tree .ant-tree-node-content-wrapper:hover .tree-item-action-wrapper {
  display: block;
}
.ant-tabs.ant-tabs-card .ant-tabs-nav-container .ant-tabs-nav-wrap {
  padding-left: 8px;
}
.ant-tabs.ant-tabs-card .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0;
}
.ant-tabs.ant-tabs-card .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: var(--primaryColor);
}
.ant-tabs.ant-tabs-card .ant-tabs-content {
  overflow: auto;
}
.ant-select-dropdown .ant-select-dropdown-content .ant-select-dropdown-menu.ant-select-dropdown-menu-vertical {
  max-height: 400px;
}
.ant-layout-sider,
.ant-layout.ant-layout-has-sider {
  min-height: 279px;
  background: transparent;
}
.ant-layout-sider .sider-tabs,
.ant-layout.ant-layout-has-sider .sider-tabs {
  background-color: #fff;
}
.ant-drawer .ant-drawer-header {
  padding: 13px 24px;
  background: #28549e;
  color: #fff;
  border-radius: 0;
}
.ant-drawer .ant-drawer-header .ant-drawer-title {
  color: #fff;
}
.ant-drawer .ant-drawer-header .ant-drawer-close {
  height: 48px;
  width: 48px;
  line-height: 48px;
  color: #fff;
}
.ant-drawer .ant-drawer-body {
  height: calc(100% - 65px);
  overflow: auto;
}
.jtl-chart-radio-button .ant-radio-button-wrapper:hover {
  color: #43a4fd !important;
}
.jtl-chart-radio-button .ant-radio-button-wrapper-checked,
.jtl-chart-radio-button .ant-radio-button-wrapper-checked:hover {
  color: #fff !important;
  background: #43a4fd !important;
  border-color: #43a4fd !important;
}
.jtl-chart-radio-button .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #43a4fd !important;
}
.jtl-chart-radio-button .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: -1px 0 0 0 #43a4fd !important;
  -webkit-box-shadow: -1px 0 0 0 #43a4fd !important;
}
.drag-row-table .ant-table .ant-table-row {
  cursor: move;
}
.ant-layout {
  background: #F0F2F5 !important;
}
.ant-descriptions-item-label {
  width: 200px;
}
