
.components-input-demo-presuffix .anticon-close-circle[data-v-1cd7cd60] {
  cursor: pointer;
  color: #ccc;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  font-size: 12px;
}
.components-input-demo-presuffix .anticon-close-circle[data-v-1cd7cd60]:hover {
  color: #f5222d;
}
.components-input-demo-presuffix .anticon-close-circle[data-v-1cd7cd60]:active {
  color: #666;
}
