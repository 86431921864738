/* Mixins */
.jtl-form-component .ant-form-item {
  display: inline-block;
}
.jtl-form-component .ant-form-item .ant-form-item-children {
  display: inline-block;
  width: 100%;
}
.jtl-form-component .ant-form-item .form-select-device__add {
  line-height: 30px;
}
.jtl-form-component .ant-form-item .hit-icon {
  color: #52c41a;
  position: absolute;
  right: -20px;
  top: 13px;
}
.jtl-form-component .ant-form-item .form-item-message {
  position: absolute;
  padding-left: 10px;
  color: #575757;
  word-break: break-all;
  width: 50%;
}
.jtl-form-component .ant-form-item .ant-form-item-label label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
  line-height: 1;
}
.jtl-form-component .jtl-form-item__attribute-data-type-value .ant-form-item-control.has-error .attribute-data-type-value-component .ant-select-selection {
  border-color: #d9d9d9 !important;
}
.jtl-form-component .jtl-form-item__attribute-data-type-value .ant-form-item-control.has-error .attribute-data-type-value-component .ant-select-arrow {
  color: #d9d9d9 !important;
}
.jtl-form-component .jtl-form-item__attribute-data-type-value .ant-form-item-control.has-error .ant-form-explain {
  margin-left: 150px;
}