/* Mixins */
.jtl-button-component {
  display: inline-block;
  overflow: hidden;
}
.jtl-button-component .el-loading-spinner {
  margin-top: -15px;
}
.jtl-button-component .el-loading-spinner .circular {
  height: 30px;
  width: 30px;
}