/* Mixins */
.jtl-table-query-component .table-query-item {
  display: block;
  line-height: 32px;
  height: 40px;
}
.jtl-table-query-component .query-control {
  vertical-align: top;
}
.jtl-table-query-component .query-control .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 20px;
  overflow-x: auto;
  display: -webkit-box;
  display: -moz-box;
}
.jtl-table-query-component .query-control .ant-select-selection--multiple .ant-select-selection__rendered ul {
  margin-right: 20px;
  overflow-x: auto;
  display: -webkit-box;
  display: -moz-box;
}