/* Mixins */
.jtl-transform-component .transform-header {
  margin-bottom: 16px;
}
.jtl-transform-component .transform-header .transform-remove-all {
  position: absolute;
  bottom: -12px;
  right: 0;
}
.jtl-transform-component .transform-column {
  border: solid 1px #d9d9d9;
  padding: 16px 0 0 16px;
  height: 425px;
}
.jtl-transform-component .transform-column-middle {
  height: 425px;
}
.jtl-transform-component .transform-name {
  display: inline-block;
  max-width: calc(100% - 50px);
  vertical-align: top;
}
.jtl-transform-component .transform-name-search {
  width: calc(100% - 16px);
}
.jtl-transform-component .transform-name-right {
  max-width: calc(100% - 38px);
}
.jtl-transform-component .transform-tag {
  float: right;
  margin-top: 2px;
}
.jtl-transform-component .right-close-icon {
  float: right;
  height: 32px;
  line-height: 32px;
  padding: 0 4px;
  margin-right: 16px;
}
.jtl-transform-component .right-close-icon:hover {
  float: right;
  color: var(--primaryColor);
}
.jtl-transform-component .ul-list {
  margin-top: 12px;
  height: 363px;
  overflow-y: auto;
}
.jtl-transform-component .ul-list li {
  height: 32px;
  line-height: 32px;
  cursor: pointer;
}
.jtl-transform-component .ul-list li .ant-checkbox-wrapper {
  width: 100%;
}
.jtl-transform-component .ul-list li:hover {
  background-color: #fffbf0;
}
.jtl-transform-component .ul-list-right {
  margin-top: 0;
}