/* Mixins */
.select-device-attribute-table-component .ant-table-body table tr td {
  height: 76px;
  padding: 2px 16px;
}
.select-device-attribute-table-component .ant-table-body table tr td .ant-form-item {
  width: 100%;
  margin-bottom: 0;
  height: 40px;
}
.select-device-attribute-table-component .ant-table-body table tr td .ant-form-item .ant-form-explain {
  margin-top: -6px;
}